import React from "react"
import Layout from "../components/layout"
import SectionTitle from "../components/common/SectionTitle"
import { Link } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"

const StyledPage = styled.div`
  max-width: 1400px;
  margin: 5rem auto;
`

const MusicSucsessPage = () => {
  return (
    <>
      <Layout>
        <StyledPage>
          <SectionTitle title="Thank you for your music submission" />
          <h2>Your music has been successfully submitted.</h2>
          <p>
            We will contact you once your music has been reviewed. Please allow
            upto 72 hours for feedback.
          </p>
          <Link to="/">Back to Home</Link>
          <br />
          <Link to="/show-submission">Back to Form</Link>
        </StyledPage>
      </Layout>
    </>
  )
}

export default MusicSucsessPage
